<template>
  <div class="home"  v-loading="loading"
    element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    >
   <div class="left-box">
      <!-- <div class="advertising">
        <div class="tab">
          <div class="ele-img">
            <img class="active" title="扫码领取" src="../assets/ad/wxxcx.jpg" alt="" />
      
          </div>
          <ul>
            <li :class='`count`+count'>优惠卷（外卖/话费/打车）</li>
          </ul>
          <a href="https://www.huya.com/xiaonan777" title="点击跳转" target="_blank" >
                <div class="ele-img">
             <img class="active" src="../assets/ad/hp.png" alt="" />
          </div>
          <ul>
            <li  >说一声康总五块钱的主播</li>
          </ul>
          </a>
        </div>
      </div> -->

      <div class="left-list">
        <ul class="list">
          <li class="logo">
            <img src="../assets/bird.png" alt />
            <span>首页</span>
          </li>

          <a
            :href="`#${item.ref}`"
            v-for="(item, index) in allData"
            :key="index"
          >
            <li>
              {{ item.title }}
            </li>
          </a>

           <li @click="toVideo">
            <span>视频倍速</span>
          </li>
        </ul>
      </div>
    </div>


    <div class="right-content">
      <div class="content-box">
        <div
          class="item"
          :id="item.ref"
          :ref="item.ref"
          v-for="(item, index) in allData"
          :key="index"
        >
          <h2>{{ item.title }}</h2>
          <div class="content">
            <div class="to-url" v-for="(k, count) in item.data" :key="count">
              <a :href="k.wxpath" target="_blank">
                <div class="title-box">
                  <img class="icon" :src="'http://wry.wwp666.cn'+k.base" alt />
                  <div class="title">{{ k.name }}</div>
                </div>
                <div class="desc">{{ k.desc }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>

       <div class="ba">Copyright © wengweipeng All Rights Reserved 粤ICP备<a href="https://beian.miit.gov.cn/#/Integrated/index">2022044923号-1</a></div>
    </div>

    <div class="scroll">
      <a href="#">
        <div class="red-packet">
          <div class="red">
            <svg
              t="1649392463664"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="8976"
              width="40"
              height="40"
            >
              <path
                d="M516.042332 352.112884c-1.892155 0-3.78431-0.172014-5.676466-0.516042L154.640685 287.607593c-15.137242-2.752226-26.318159-15.997312-26.318159-31.478582L128.322526 160.145137c0-52.808332 43.003528-95.983874 95.983874-95.983874L799.865614 64.161263c52.808332 0 95.983874 43.003528 95.983874 95.983874l0 95.983874c0 15.48127-11.008903 28.726356-26.146145 31.478582l-347.812531 63.989249C519.826642 351.94087 517.934487 352.112884 516.042332 352.112884zM192.139761 229.466823l323.730556 58.140769 315.817907-58.140769L831.688224 160.145137c0-17.545439-14.277171-31.994625-31.994625-31.994625L224.134386 128.150512c-17.545439 0-31.994625 14.277171-31.994625 31.994625C192.139761 160.145137 192.139761 229.466823 192.139761 229.466823z"
                p-id="8977"
                fill="#ffffff"
              ></path>
              <path
                d="M799.865614 959.666723 224.134386 959.666723c-52.808332 0-95.983874-43.003528-95.983874-95.983874L128.150512 384.107509c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 479.747354c0 17.717453 14.277171 31.994625 31.994625 31.994625L799.865614 895.849488c17.717453 0 31.994625-14.277171 31.994625-31.994625L831.860239 384.107509c0-17.717453 14.277171-31.994625 31.994625-31.994625 17.717453 0 31.994625 14.277171 31.994625 31.994625l0 479.747354C895.677474 916.663195 852.673946 959.666723 799.865614 959.666723z"
                p-id="8978"
                fill="#ffffff"
              ></path>
              <path
                d="M607.725853 639.892491c17.717453 0 31.994625-14.277171 31.994625-31.994625s-14.277171-31.994625-31.994625-31.994625l-51.776247 0 76.030237-73.278011c12.729044-12.213002 13.073072-32.510667 0.860071-45.067697-12.213002-12.729044-32.510667-12.901058-45.239711-0.688056l-76.202251 73.622039-73.278011-73.105997c-12.55703-12.55703-32.854695-12.55703-45.239711 0-12.55703 12.55703-12.385016 32.510667 0 45.067697l73.966068 73.622039-51.088191 0c-17.717453 0-31.994625 14.277171-31.994625 31.994625s14.277171 31.994625 31.994625 31.994625L479.919368 640.064505l0 31.994625-64.161263 0c-17.717453 0-31.994625 14.277171-31.994625 31.994625 0 17.717453 14.277171 31.994625 31.994625 31.994625L479.919368 736.048379l0 64.333277c0 17.717453 14.277171 31.994625 31.994625 31.994625 17.717453 0 31.994625-14.277171 31.994625-31.994625l0-64.333277 63.817235 0c17.717453 0 31.994625-14.277171 31.994625-31.994625 0-17.717453-14.277171-31.994625-31.994625-31.994625l-63.817235 0L543.908618 639.892491 607.725853 639.892491z"
                p-id="8979"
                fill="#ffffff"
              ></path>
            </svg>
          </div>
          <div class="show-red">
            <img src="../assets/ad/red.jpg" alt="" />
          </div>
        </div>
        <div class="srcoll-box">
          <svg
            t="1649392326031"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="7708"
            width="40"
            height="40"
          >
            <path
              d="M792.748 599.403l-257.312-259.001c-5.7-5.73-13.683-8.998-23.437-8.998s-16.541 3.881-21.63 8.971l-261.758 260.585c-11.205 11.174-11.231 29.313-0.031 40.513 13.731 13.731 34.927 5.614 40.544 0.056l214.226-213.168v372.648c0 15.844 12.835 28.653 28.649 28.653 15.817 0 28.653-12.813 28.653-28.653v-374.053l211.469 212.845c5.587 5.617 12.981 8.453 20.311 8.453 7.337 0 14.615-2.784 20.202-8.338 11.257-11.148 11.288-29.313 0.113-40.514v0 0zM827.161 251.635h-630.316c-15.817 0-28.653-12.835-28.653-28.645 0-15.818 12.835-28.653 28.653-28.653h630.316c15.84 0 28.645 12.835 28.645 28.653 0 15.81-12.805 28.645-28.645 28.645v0 0zM827.161 251.635z"
              p-id="7709"
              fill="#ffffff"
            ></path>
          </svg>
        </div>
      </a>
    </div>

  </div>
</template>

<script>
import '../assets/less/home.less'

import { data } from '../data/linedata.js'

export default {
  name: 'Home',

  data() {
    return {
      count:0,
      show: false,
      index: 0,

      allData: [],
      time:null,
      loading:true
    }
  },

  created(){

    this.getUrl()
    this.time = setInterval(()=>{
      this.count++;

      if(this.count>3){
        this.count = 0
      }
    },1000)
  },

  methods:{
    toVideo(){
      this.$router.push({path:'/video'})
    },

    // 获取网址
    getUrl(){

      this.axios({
        method: 'get',
        url: 'http://wry.wwp666.cn/findurl',
        // headers: {
        //   token: this.wwp,
        // },
      })
        .then((res) => {
          // 查询成功
          // console.log('res.data',res.data);

          this.allData = res.data.result.map((item,index)=>{
            let obj = {}
            obj.title = item.type;
            obj.data = item.items;
            obj.ref = 'box'+(index+1)

            return obj;
          })

          this.loading = false;
        })
        .catch((err) => {
          
          this.loading = false;
          this.allData = data;
        })
    }
  },

  beforeDestroy(){
    clearInterval(this.time);
    this.time = null;
  }

}
</script>
